// Loaded after app.js
//require('./screens/register.js');
//require('./screens/login.js');
require('./custom.js');
require('./custom/bootstrap.js');
require('./custom/cookies.js');
require('./custom/embed.js');
require('./custom/forms.js');
//require('./custom/header.js');
//require('./custom/menu.js');
require('./custom/scroll.js');
//require('./custom/slick.js');
//require('./custom/tabs.js');
//require('./custom/maps.js');
require('./custom/multiselect.js');
require('./helpers/analytics.js');
